
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: "Montserrat", sans-serif;
  color: #444;
  background: rgb(255, 255, 255);
  height: 100vh;
}

nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2% 2.5% 2% 3%;
  background-color: white;
  gap: 60px;
  border-bottom: #d7d8da solid 1px;
}

.logo {
  display: flex;
  align-items: center;
  width: 40%;
  height: 30%;
  gap: 10px;
}

.logo-img {
  display: flex;
  width: 14%;
  height: 20%;
}

.logo-img h2 {
  margin: 0.2em;
}

.nav-items {
  display: flex;
  flex-direction: row;
  justify-content: right;
  align-items: center;
  width: 40%;
}

.nav-items li {
  list-style: none;
  margin: 0.2em 0.4em;
  padding: 0 0.4em;
}

.nav-items li a {
  display: block;
  text-decoration: none;
  color: blue;
  margin: 0;
}

.nav-items li a.active {
  text-decoration: underline;
}

.prof {
  border-left: #444 solid 2px;
  padding-left: 18px;
}

table {
  margin: 1.5% 3%;
  border-collapse: collapse;
}

table td,
table th {
  border: 1px solid #444;
}

table th {
  text-align: left;
  background-color: white;
  border: 1px solid #444;
}

.mission-list th,
tr {
  padding: 0.3em 0.5em;
  border-bottom: 1px solid #fff;
}

table td {
  border: 1px solid #444;
}

.mission-list th {
  padding: 0.3em 0.5em;
  border-bottom: 1px solid #fff;
  background: #fff;
  color: #000;
  font-weight: 700;
  font-size: 1.5em;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.mission-list td {
  padding: 0.3em 0.5em;
  border-bottom: 1px solid #fff;
}

table tr:nth-child(odd) { background-color: #f2f2f2; }

table tr:hover { background-color: #ddd; }

.rocketlist {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.rocket {
  display: flex;
  width: 93%;
  height: 250px;
  box-shadow: 0 1px 1px 2px #1c1c1c;
  margin: 1em;
  padding: 1em;
}

.rocket-img {
  width: 250px;
  height: 100%;
  box-shadow: 0 1px 2px 2px #ccc;
}

.rocket-info {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  text-align: left;
  color: #000;
  padding: 0.5em 1em;
}

.rocket-info h3 {
  margin: 1em 0;
  color: #000;
}

button {
  cursor: pointer;
}

.activebtn {
  width: 200px;
  display: block;
  padding: 0.4em 0.7em;
  margin: 1em;
  align-self: center;
}

.mission-list {
  width: 100%;
  align-self: center;
  color: #fff;
  text-align: center;
  margin-top: 1.5em;
}

.mission-Card {
  width: auto;
  height: auto;
  margin: 0.5em 1em;
  color: #fff;
  cursor: pointer;
}

.desc {
  padding: 0.5em 0.7em;
  text-align: left;
  font-size: 0.9em;
}

.non-member-badge,
.member-badge,
.leave-mission-btn,
.join-mission-btn {
  width: 100px;
  height: auto;
  margin: 0.2em 10px;
  padding: 1em 1.2em;
  border-radius: 5px;
  font-size: 0.8em;
  text-align: center;
  border: none;
  font-weight: bold;
}

.non-member-badge,
.member-badge {
  color: #1c1c1c;
}

.non-member-badge {
  background-color: rgb(116, 116, 116);
}

.member-badge {
  background-color: #00d7c8;
}

.leave-mission-btn,
.join-mission-btn {
  height: 32px;
  background: none;
}

.join-mission-btn {
  border: 1px solid grey;
}

.leave-mission-btn {
  border: 1px solid rgb(255, 70, 70);
  color: rgb(255, 70, 70);
}

.non-member,
.member {
  padding: 5% 0;
  width: 100px;
  border-radius: 2px;
  font-size: 10px;
  text-align: center;
  border: none;
  font-weight: 500;
  background-color: rgb(116, 116, 116);
  color: white;
}

.member {
  background-color: #1c94b3;
}

.jion-mission,
.leave-mission {
  width: 100px;
  padding: 5% 0;
  text-align: center;
}

.leave-mission {
  color: red;
  border: red solid 1px;
  border-radius: 3px;
}

.member-table {
  width: 12.5%;
  padding: 0.5%;
}

.mission-dscrp {
  padding: 0.5%;
  font-size: 12px;
}

.mission-name {
  width: 12.5%;
  padding: 0.5%;
  font-weight: 600;
  font-size: 14px;
  text-align: start;
}

.bookings-list {
  list-style: none;
}

.dot {
  color: white;
}

@media (max-width: 500px) {
  .rocket {
    flex-direction: column;
    height: auto;
    align-items: center;
  }

  .rocket-info {
    align-items: center;
  }

  .rocket-info p {
    text-align: justify;
    margin-bottom: 1em;
  }

  .rocket-info span {
    order: -1;
  }
}
